import React from 'react'
import css from './ListingPage.module.css';
import { FormattedMessage } from 'react-intl';

const SectionCategoryMaybe = props => {
    const {
        currentListing,
        categories,
    } = props
    const { categoryLevel1, categoryLevel2 } = currentListing?.attributes.publicData || {}

    const selectedCategory = categories?.find(category => category.id == categoryLevel1) || {}
    const selectedSubCategory = selectedCategory?.subcategories?.find(subcategory => subcategory.id == categoryLevel2) || {}
    const categoryDisplay = selectedCategory?.name || ""
    const subcategoryDisplay = selectedSubCategory?.name || ""
    return < >
        <h2 style={{ marginBottom: '0' }} className={css.categorySection}><FormattedMessage id="SectionCategoryMaybe.title" /></h2>
        <p className={css.categorySection}>
            {categoryDisplay} {subcategoryDisplay}
        </p>
    </>

}

export default SectionCategoryMaybe